import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const usePaginationStore = defineStore('pagination', () => {
  const currentPage = ref(1)
  const totalPages = ref(1)

  const pagesToShow = computed(() => {
    const pages = []
    for (let i = 1; i <= totalPages.value; i++) {
      pages.push(i)
    }
    const start = Math.max(1, currentPage.value - 4) // Start 4 pages before current
    const end = Math.min(totalPages.value, start + 4) // Show 5 pages in total
    return pages.slice(start - 1, end)
  })

  const visiblePages = computed(() => {
    const start = Math.max(1, currentPage.value - 4) // Start 4 pages before current
    const end = Math.min(totalPages.value, start + 4) // Show 5 pages in total
    return pagesToShow.value.slice(start - 1, end)
  })
  function changePage(pageNumber: number) {
    if (pageNumber < 1 || pageNumber > totalPages.value) return
    currentPage.value = pageNumber
  }

  function setTotalPages(pages: number) {
    totalPages.value = pages
  }

  return {
    currentPage,
    totalPages,
    pagesToShow,
    changePage,
    setTotalPages
  }
})
